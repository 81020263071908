$body-bg: #f5f5f5;
$theme-colors: (
	'site-primary': #243875,
	'site-secondary': #7f7f7f,
	'site-muted': #5f687b,
	'site-gray': #3e4450,
	'site-text': #444851,
);

$spacer: 1rem !default;
$spacers: (
	0: 0,
	1: (
		$spacer * 0.25,
	),
	//4px
	2:
		(
			$spacer * 0.5,
		),
	//8px
	3: $spacer,
	//16px
	4:
		(
			$spacer * 1.5,
		),
	//24px
	5:
		(
			$spacer * 3,
		),
	//48px
	6:
		(
			$spacer * 4,
		),
	//64px
	7:
		(
			$spacer * 5,
		),
	//80px
	8:
		(
			$spacer * 6.25,
		),
	//100px
	9:
		(
			$spacer * 7.5,
		),
	//120px
	10:
		(
			$spacer * 9.375,
		),
	//150px
	-1: (
			$spacer * -0.25,
		),
	//4px
	-2: (
			$spacer * -0.5,
		),
	//8px
	-3: -$spacer,
	//16px
	-4: (
			$spacer * -1.5,
		),
	//24px
	-5: (
			$spacer * -3,
		),
	//48px
	-6: (
			$spacer * -4,
		),
	//64px
	-7: (
			$spacer * -5,
		),
	//80px
	-8: (
			$spacer * -6.25,
		),
	//100px
	-9: (
			$spacer * -7.5,
		),
	//120px
	-10: (
			$spacer * -9.375,
		) //150px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);
@import 'node_modules/bootstrap/scss/bootstrap';

html {
	font-size: 16px;
	@media (max-width: 1799.98px) {
		font-size: 15px;
	}
	@media (max-width: 1399.98px) {
		font-size: 14px;
	}
	@media (max-width: 1199.98px) {
		font-size: 13px;
	}
	@media (max-width: 991.98px) {
		font-size: 12px;
	}
	@media (max-width: 567.98px) {
		font-size: 11px;
	}
}
$weights: 100 200 300 400 500 600 700 800;
@each $weight in $weights {
	.fw--#{$weight} {
		font-weight: $weight;
	}
}
@for $size from 12 through 96 {
	.fs--#{$size} {
		font-size: $size/16 + rem;
	}
}
*:focus {
	border-color: inherit !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.pointer {
	cursor: pointer;
}
/* width */
::-webkit-scrollbar {
	width: 5px;
	border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
	background: theme-color('site-secondary');
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: theme-color('site-primary');
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: theme-color('site-primary');
}
.custom-navbar {
	background: #ffffff;
	box-shadow: -11px -1px 11px rgba(0, 0, 0, 0.25);
	&__brand {
		letter-spacing: 0.05em;
		color: theme-color('site-primary') !important;
	}
	&__menu a {
		line-height: 21px;
		letter-spacing: 0.05em;
		color: #6e687b !important;
		@media (max-width: 767.98px) {
			background: theme-color('site-primary');
			border-bottom: 2px solid white;
			color: white !important;
		}
	}
}
.btn {
	box-shadow: 0px 6px 15px -1px rgba(0, 0, 0, 0.75);
	border-radius: 5px;
	padding: 10px 40px;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.05em;
	color: #ffffff;
	@media (max-width: 767.98px) {
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}
}
.btn-primary {
	background: theme-color('site-primary') !important;
}
.btn-alt {
	background: theme-color('site-secondary') !important;
}
.home {
	&__heading {
		padding-top: 100px;
		line-height: 66px;
		letter-spacing: 0.05em;
		color: theme-color('site-gray');
	}
}
.liquidity {
	margin-top: 150px;
	&__heading {
		line-height: 47px;
		letter-spacing: 0.05em;
		color: theme-color('site-muted');
	}
	&__text {
		line-height: 21px;
		color: theme-color('site-text');
	}
	&__simple-tab {
		cursor: pointer;
		padding: 20px 10px 35px 10px;
		line-height: 33px;
		letter-spacing: 0.05em;
		color: #6e687b;
	}
	&__active-tab {
		cursor: pointer;
		text-transform: uppercase;
		padding: 20px 10px 35px 10px;
		line-height: 33px;
		letter-spacing: 0.05em;
		color: theme-color('site-primary');
		border-bottom: 4px solid theme-color('site-primary');
	}
}
.lplock {
	border: 1px solid #000000;
	border-radius: 20px;
	padding: 20px 20px 40px 20px;
	&__title {
		line-height: 28px;
		letter-spacing: 0.05em;
		color: theme-color('site-text');
	}
	&__input {
		border: 0 !important;
		height: 50px;
		width: 65%;
		color: theme-color('site-text') !important;
		@media (max-width: 767.98px) {
			width: 100%;
		}
	}
	&__address {
		line-height: 24px;
		letter-spacing: 0.05em;
		color: theme-color('site-text');
		padding-left: 20px;
	}
	&__percentage {
		line-height: 44px;
		color: theme-color('site-primary');
	}
	&__badge {
		background: rgba(90, 176, 255, 0.14) !important;
		border-radius: 30px;
		padding: 8px 20px;
		font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		color: theme-color('site-primary');
		cursor: pointer;
	}
	&__flat-feet {
		line-height: 28px;
		letter-spacing: 0.05em;
		color: #444851;
	}
}
.all-lock {
	
	&__reward {
		padding: 20px 0px 0px 0px;
		&-title {
			line-height: 34px;
			letter-spacing: 0.05em;
			color: theme-color('site-primary');
		}
		&-subtitle {
			line-height: 23px;
			letter-spacing: 0.01em;
			color: rgba(0, 0, 0, 0.63);
		}
	}
	.lock-item{
		background: rgba(90, 176, 255, 0.28);
	}
}
.token-vesting {
	&__title {
		line-height: 47px;
		letter-spacing: 0.05em;
		color: theme-color('site-muted');
	}
	&__desc {
		line-height: 22px;
		color: theme-color('site-text');
	}
}

.lplock__flat-feet{
	cursor: pointer;
}

.timer{
	font-size: 24px;
}